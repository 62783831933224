import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
// import SectionHeader from './partials/SectionHeader';
import './Contactpage.css';
import outside from './outside.png';
import ContactForm from './ContactForm';
import SimpleMap from './SimpleMap';
import { Link } from 'react-router-dom';


const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const ContactPage = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  imageFill,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  invertMobile,
  invertDesktop,
  alignTop,
  ...props
}) => {

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  // const sectionHeader = {
  //   title: 'Contact Us',
  //   paragraph: 'Vitae aliquet nec ullamcorper sit amet risus nullam eget felis semper quis lectus nulla at volutpat diam ut venenatis tellus—in ornare.'
  // };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className={tilesClasses}>
            <div className={splitClasses}>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-top" data-reveal-container=".split-item">
            <div className='div'>
              <div>
                {/* <h3>Contact Us</h3> */}
                <div className='div'>
                  <div>
                <img src={outside} alt="location" className='im'
                />
                </div>
                <div>
                <p>3325 N Service Rd Unit 107, Burlington, ON <br></br> L7N 3G2</p>
                <p>Email: info@gatech.ca</p>
                <p>Phone: (289) 799-2700</p>
                <p>___________________________________</p>
                <p>Hours:
                <br/>Monday-Friday	8a.m.–5p.m.<br/>Saturday/Sunday Closed

                </p>
               
                </div>
                </div>
                
                
              </div>
              </div>
              
              </div>
              <div className="split-item">
              <div className="split-item-image center-content-mobile reveal-from-bottom" data-reveal-container=".split-item">
        
            <div className='div'>
              <div className='div' >
                <h3>About Us</h3>
                <p>For over 15 years Global Automation Technology Ltd. has been dedicated to providing high quality products and services. The company's focus is always on helping clients achieve an optimal solution for their business needs regardless of location. </p>  
{/* <p>
Our team has worked with clients such as;                                                                     .
</p> */}
<p>
We have helped numerous companies find cost effective solutions and look forward to helping you find yours. Our team has a wide-range of experience and we work until the job is finished. Contact us today. 
 </p>
 <ContactForm/>
 
              </div>
              
              </div>
              </div>
              </div>
            </div>
            </div>
            
          </div>
        </div>
        <div className='gg'>
        <Link to="/maps" className="button button-primary button-wide-mobile button-sm" >Open in Maps</Link>
        </div>
        <SimpleMap/>
        
      </div>
    </section>
  );
}

ContactPage.propTypes = propTypes;
ContactPage.defaultProps = defaultProps;

export default ContactPage;