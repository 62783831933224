import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Contact from './views/Contact';
import Products from './views/Products'
import Services from './views/Services';
import Gallery from './views/Gallery';

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/contact" component={Contact} layout={LayoutDefault} />
          <AppRoute exact path="/products" component={Products} layout={LayoutDefault} />
          <AppRoute exact path="/services" component={Services} layout={LayoutDefault} />
          <AppRoute exact path="/gallery" component={Gallery} layout={LayoutDefault} />
          <AppRoute exact path="/maps" component={() => {
              window.location.replace('https://www.google.com/maps/place/Global+Automation+Technology/@43.3616405,-79.7951766,15z/data=!4m5!3m4!1s0x0:0x6ca507af12307311!8m2!3d43.3616405!4d-79.7951766');
              return null;
            }} />
          
        </Switch>
      )} />
  );
}

export default App;