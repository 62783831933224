import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeatureSplitServices = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Services',
    // paragraph: 'Here is a list of our services offered:'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                PLC Programming
                  </h3>
                <p className="m-0">
                Our team has a wide-range of experience working with Programmable Logic Controllers (PLC's) and Human Machine Interfaces (HMI's). Contact us today to learn how we can find the right solution for you.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/Free-Standing-Control-Panel-1.jpg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
          
                <h3 className="mt-0 mb-12">
                Factory Acceptance Testing
                  </h3>
                <p className="m-0">
                 Factory Acceptance Testing ensures operational safety. Factory Acceptance Testing is unique to each piece of equipment.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/factory-acceptance-test.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
            
                <h3 className="mt-0 mb-12">
                Panel Integration
                  </h3>
                <p className="m-0">
                  We design and manufacture control panels for companies across various industries. Contact us today to see how we can find a solution to fit your needs.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/IMG_18877.JPG')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
          
                <h3 className="mt-0 mb-12">
                  Electrical Design and Drawing
                  </h3>
                <p className="m-0">
                  Our team specializes in electrical design and drawing. Our services include AutoCAD Electrical design and project design for control panels.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/iStock-1176842849-1080-crop-1080x675.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
            
                <h3 className="mt-0 mb-12">
                HMI Programming
                  </h3>
                <p className="m-0">
                  Let us program your Human Machine Interface (HMI). We have extensive knowledge and experience programming top HMI manufactures such as Siemens, Allen Bradley, Mitshubishi, and AutomationDirect.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/hmi_blog.jpg')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>
            

          </div>
        </div>
      </div>
    </section>
  );
}

FeatureSplitServices.propTypes = propTypes;
FeatureSplitServices.defaultProps = defaultProps;

export default FeatureSplitServices;