import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import iconmap from './iconmap.png'
import Marker  from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 43.3616405,
      lng:-79.7951766
    },
    zoom: 16
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '500px', width: '100%', paddingBottom: '30px' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDkqaUwwc8XERwE80mPoNoB-E1FJHpBk7w" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={43.362389317837724}
            lng={-79.79509076931289}
            text="Global Automation Technology Ltd."
           />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;