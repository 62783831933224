import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './ContactForm.css'

export const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    window.alert("Message Sent")

    emailjs.sendForm('service_g9gpnlc', 'template_2w6vxb7', form.current, 'user_lLFT2bozQpLY6L2vt3yhx')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <label>Name</label><br/>
      <input type="text" name="user_name" /><br/>
      <label>Email</label><br/>
      <input type="email" name="user_email" /><br/>
      <label>Message</label><br/>
      <textarea name="message" /><br/>
      <input type="submit" value="Send" />
    </form>
  );
}
export default ContactForm;
