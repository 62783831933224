import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import './Gallery.css'

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeatureSplitGallery = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Gallery',
    
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            
       

            <div className="split-item">
            <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image 
                  src={require('./../../assets/images/IMG_1846.JPG')}
                  alt="Features split 02"
                  width={528}
                  height={396}
                  className='image'
                   />
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/IMG_1887.JPG')}
                  alt="Features split 03"
                  width={528}
                  height={396}
                  className='image' />
              </div>
            </div>

            <div className="split-item">
            <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/IMG_1939.JPG')}
                  alt="Features split 02"
                  width={528}
                  height={396}
                  className='image'
                   />
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/IMG_1513.JPG')}
                  alt="Features split 03"
                  width={528}
                  height={396}
                  className='image' />
              </div>
            </div>

            <div className="split-item">
            <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/IMG_1845.JPG')}
                  alt="Features split 02"
                  width={528}
                  height={396}
                  className='image'
                   />
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/IMG_1790.JPG')}
                  alt="Features split 03"
                  width={528}
                  height={396}
                  className='image' />
              </div>
            </div>

            <div className="split-item">
            <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/IMG_1148.JPG')}
                  alt="Features split 02"
                  width={528}
                  height={396}
                  className='image'
                   />
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/IMG_1727.JPG')}
                  alt="Features split 03"
                  width={528}
                  height={396}
                  className='image'
                   />
              </div>
            </div>
            

          </div>
        </div>
      </div>
    </section>
  );
}

FeatureSplitGallery.propTypes = propTypes;
FeatureSplitGallery.defaultProps = defaultProps;

export default FeatureSplitGallery;