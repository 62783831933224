import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
// import { Link } from 'react-router-dom';
import "./Whatwedo.css";
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const Whatwedo = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    bottomDivider && 'has-bottom-divider',
    topDivider && 'has-top-divider'
    
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {

  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
        <div className='container'>
      <div className="container1">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                What We Do
                  </h3>
                <p >
                  We provide a number of valuable products and services listed below:
                  </p>
                  <p>
                     -Custom Control Panels<br/>-PLC Control Panels and Programming<br/> -Variable Frequency Drives<br/>-Engraving and Labels<br/>- Protection and Control<br/>-Factory Acceptance Testing<br/>-Panel integration<br/>-Electrical Design and Drawing<br/>-HMI Programming
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/service-panels-1.jpg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
                  <p>___________________________________________________________</p>
                   {/* <div className='div'>
                        <Link to="/products" className="button button-primary button-wide-mobile button-sm">Products</Link>
                        <p></p>
                        <Link to="/services" className="button button-primary button-wide-mobile button-sm">Services</Link>
                      </div> */}
                      <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="/products">
                    Products
                    </Button>
                    <Button tag="a" color="primary" wideMobile href="/services">
                    Services
                    </Button>
              
                </ButtonGroup>
              </div>
              </div>
              
                    
                      
            </div>

            



           
            

          </div>
        </div>
      </div>
      </div>
    </section>
  );
}

Whatwedo.propTypes = propTypes;
Whatwedo.defaultProps = defaultProps;

export default Whatwedo;